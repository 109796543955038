import { ChangeDetectorRef, Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatLabel } from '@angular/material/input';
import { SnackbarService } from '../../core/services/snackbar.service';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../core/api/auth.service';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
@Component({
  selector: 'app-login',
  standalone: true,

  imports: [
    CommonModule,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatFormFieldModule,
    NzCheckboxModule,
    FormsModule,
    RouterModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  public formLogin: FormGroup = this.fb.group({
    userName: [null, Validators.required],
    password: [null, Validators.required],
  });
  remember: boolean = false;
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private _snackBar: SnackbarService,
    private auth: AuthService,
    private router: Router,
  ) {}

  login() {
    const body = {
      username: this.formLogin.get('userName')?.value,
      password: this.formLogin.get('password')?.value,
      rememberMe: true,
    };
    if (this.formLogin.invalid) {
      this.formLogin.get('userName')?.markAsTouched();
      this.formLogin.get('password')?.markAsTouched();
      return;
    }
    this.auth.login(body).subscribe({
      next: (res) => {
        localStorage.removeItem('access_token');
        localStorage.setItem('access_token', res.id_token);
        this.router.navigate(['/']);
      },
      error: (err) => {
        console.log(err)
        this._snackBar.error('Tài khoản hoặc mật khẩu không đúng');
      }
    })
  }

  hide: boolean = true;
  showPass(e: any) {
    const inputPass = document.querySelector('#inputPass') as HTMLInputElement;
    if (inputPass?.type === 'password') {
      inputPass.type = 'text';

      this.hide = false;
    } else {
      inputPass.type = 'password';
      this.hide = true;
    }
  }
  forgotPassword() {}
}
