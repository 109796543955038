import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { CookieService } from 'ngx-cookie-service';
import { DBConfig, provideIndexedDb } from 'ngx-indexed-db';
import { authCodeFlowConfig } from '../environments/environment';
import { routes } from './app.routes';
import { authAppInitializerFactory, endpointsInitializerFactory } from './core/auth/auth-app-initializer.factory';
import { AuthService } from './core/auth/auth.service';
import { AppConfigService } from './core/config/config.service';
import { Interceptor } from './core/interceptor/interceptor.interceptor';
import { AuthenEffects } from './store/auth/authen.effects';
import { metaReduder, reducers } from './store/index';
export const  MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
export function storageFactory(): OAuthStorage {
  return localStorage;
}
// const customCurrencyMaskConfig = {
//   align: 'left',
//   thousands: '.',
//   prefix: '',
//   decimal: ',',
//   precision: 0,
//   allowNegative: false,
// };
const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ",",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: "."
};

const dbConfig: DBConfig = {
  name: 'POS DATA',
  version: 1,
  objectStoresMeta: [
    {
      store: 'ReceiptData',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'page', keypath: 'page', options: { unique: false } },
        { name: 'productList', keypath: 'productList', options: { unique: false } },
        { name: 'customer', keypath: 'customer', options: { unique: false } },
        { name: 'customerMoney', keypath: 'customerMoney', options: { unique: false } },
        { name: 'voucherCode', keypath: 'voucherCode', options: { unique: false } },
        { name: 'note', keypath: 'note', options: { unique: false } },
        { name: 'coin', keypath: 'coin', options: { unique: false } },
      ]
    }
  ]
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    provideHttpClient(withInterceptors([Interceptor])),
    provideStore(reducers, { metaReducers:metaReduder }),
    provideEffects( AuthenEffects),
    importProvidersFrom(OAuthModule.forRoot()),
    provideIndexedDb(dbConfig),
    provideAnimations(),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true, // If set to true, the connection is established within the Angular zone
    }),

    // provideOAuthClient(),
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: authAppInitializerFactory,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: AuthConfig,
      useValue: authCodeFlowConfig,
    },
    {
      provide: OAuthModuleConfig,
      useFactory: endpointsInitializerFactory,
      deps: [AppConfigService],
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {provide: CURRENCY_MASK_CONFIG, useValue: customCurrencyMaskConfig},
    JwtHelperService,
  ],
};
