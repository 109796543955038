<div class="back_bg grid grid-cols-1 lg:grid-cols-2 gap-10">

    <div class="mt-[16vh] px-[50px] lg:px-[130px]">
        <p class="text-center text-primary text-xl font-normal !mb-0">Đăng nhập</p>
        <h1 class="text-center text-primary text-[25px] font-normal !mb-0">Công ty cổ phần Công nghệ TrueConnect</h1>
        <p class="text-center py-3">
            <span class="font-normal mr-3">Bạn chưa có tài khoản</span>
            <span class="font-normal text-primary cursor-pointer">Đăng ký ngay</span>
        </p>

        <form [formGroup]="formLogin" class="">

            <div class="flex flex-col">
                <label for="userName" class="mb-2 mt-6">Tên đăng nhập</label>
                <mat-form-field class="custom-input" appearance="outline" class="!h-8">
                    <input formControlName="userName" name="userName" matInput class="!h-8 border border-[#d9d9d9]" />
                    <mat-error>Không bỏ trống trường này</mat-error>
                </mat-form-field>
            </div>

            <div class="flex flex-col">
                <label for="password" class="mb-2 mt-6">Mật khẩu</label>
                <mat-form-field class="custom-input relative" appearance="outline" class="!h-8">
                    <input formControlName="password" name="password" matInput type="password" id="inputPass" class="!h-8 border border-[#d9d9d9]" />
                    <i *ngIf="hide" class="fas fa-eye absolute right-0 top-[50%] translate-y-[-50%]"
                        (click)="showPass($event)"></i>
                    <i *ngIf="!hide" class="fa-solid fa-eye-slash absolute right-0 top-[50%] translate-y-[-50%]"
                        (click)="showPass($event)"></i>
                    <mat-error>Không bỏ trống trường này</mat-error>
                </mat-form-field>
            </div>

        </form>

        <div class=" flex flex-col gap-y-5 mt-6">
            <div class="flex justify-between">
                <label nz-checkbox [(ngModel)]="remember">Ghi nhớ đăng nhập</label>
                <a class="text-primary underline hover:text-primary" (click)="forgotPassword()">Quên mật khẩu?</a>
            </div>
            <a [routerLink]="'/'" class="text-primary underline hover:text-primary">Hoặc đăng nhập với tài khoản TrueConnect</a>
            <div class="flex gap-5 justify-center">

                <button class="bg-primary text-white w-full rounded-[5px] h-[30px] hover:opacity-50"
                    (click)="login()">Đăng
                    nhập</button>
            </div>
        </div>
        <p class="text-primary text-center pt-5">
            <a target="_blank" class="cursor-pointer !text-primary" href="https://hdsd.trueconnect.vn/hdsd">HDSD</a>
            <span class="!mx-2">|</span>
            <a href="https://intro.trueconnect.vn/tac" target="_blank" class="cursor-pointer !text-primary">Điều khoản</a>
            <span class="!mx-2">|</span>
            <a href="https://intro.trueconnect.vn/privacy" target="_blank" class="cursor-pointer !text-primary">Bảo mật</a>
        </p>
        <p class="font-semibold text-center pt-1">Powered by TrueConnect</p>
    </div>

    <div class="hidden lg:block my-auto px-[150px]">
        <img alt="logo" class="w-[250px] mx-auto pb-[70px] cursor-pointer" src="https://cdn.eztek.net/Eztek/trueretail/noti_638616266988651170_ORIGIN.webp">
        <p class="text-center font-normal text-[30px]">Kết sức mạnh, Nối thành công</p>
    </div>

</div>