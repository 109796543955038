import { WarehouseService } from './../../core/api/warehouse.service';
import { createReducer, on } from '@ngrx/store';
import { authAction } from './shared.action';

export interface State {
  userInfor:any,
  WarehouseId:any
}

export const initialState: State = {
  userInfor: {},
  WarehouseId: '',
};

export const reducer3 = createReducer<State>(
  initialState,

  on(authAction.getAuthInfoDone, (state,  data :any) => ({
    ...state,
    userInfor: data.data,
  })),
  on(authAction.getAuthInfoFali, (state,  data :any) => ({
    ...state,
    userInfor: {},
  })),
  on(authAction.getWarehouseIdInfoDone, (state,  data :any) => ({
    ...state,
    WarehouseId:data.data ,
  })),
  on(authAction.getWarehouseIdInfoFali, (state,  data :any) => ({
    ...state,
    WarehouseId:'' ,
  }))
);
