import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const authAction = createActionGroup({
  source: 'auth',
  events: {

    'Get Auth Info done': props<{data: any}>(),
    'Get Auth Info fali': emptyProps(),
    'Get WarehouseId Info done': props<{data: any}>(),
    'Get WarehouseId Info fali': emptyProps(),
  },
});
