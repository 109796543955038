import { isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { TabBarReducer } from '../features/pay-at-the-counter/state/tabBar.reducer';
import { ChangeCollapsedReducer } from '../layouts/main/states/main.reducer';
import { reducer3 } from './auth/shared.reducer';


export interface State {}
export const reducers: ActionReducerMap<State> = {
  tabBarReducer: TabBarReducer,
  changeCollapsedReducer: ChangeCollapsedReducer,
  auth:reducer3
};

export const metaReduder: MetaReducer<State>[] = isDevMode()? []: [];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    let nextState = reducer(state, action);
    let saveData:any
    if (action.type === 'INIT' || action.type == 'UPDATE') {
      const storageValue = sessionStorage.getItem('state');
      if (storageValue) {
        try {
          return JSON.parse(storageValue)
          ;
        } catch {
          localStorage.removeItem('state');
        }
      }

    }
    action.type.includes('productState') && sessionStorage.setItem('state', JSON.stringify(action));
    return nextState;
  };
}


