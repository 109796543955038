import { createReducer, on } from "@ngrx/store";
import { tabBarAction } from "./tabBar.action";

const initialState = [
  {
    page: 1,

  }
];
export const TabBarReducer = createReducer(
  initialState,
  on(tabBarAction.addTab, (state) => {
    let max = Math.max(...state.map(item => item.page));
    if(max == -Infinity){
      max = 0;
    }
    return [
      ...state,
      {page: max + 1}
    ]
  }),
  on(tabBarAction.removeTab, (state, { data }) => {
    return [...state.filter((item, index) => item.page !== data.page)]
  }),

)