import { createAction, createActionGroup, emptyProps, props } from "@ngrx/store";

export const tabBarAction = createActionGroup({
  source: "tabBarState",
  events: {
    "addTab": emptyProps(),
    "removeTab":  props<{data: {page: number}}>(),
  }
})
export const addTabBarAction = createAction("addTab");
export const removeTabBarAction = createAction("removeTab",props<{data: {page: number}}>())