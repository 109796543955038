import { Injectable } from '@angular/core';
import { AppConfig } from './config.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService<T extends AppConfig = AppConfig> {
  static appConfig: AppConfig;
  constructor() {}

  static loadConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
          return  environment
      });
  }

  getConfig() {
    return environment;
  }
}
