import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzI18nService, vi_VN } from 'ng-zorro-antd/i18n';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AuthService } from './core/api/auth.service';
import { authAction } from './store/auth/shared.action';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    NzMessageModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NzDropDownModule,
    NzToolTipModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
  ],
})
export class AppComponent implements OnInit {
  title = 'POS';
  _store = inject(Store);

  constructor(
    private auth: AuthService,
    private i18n: NzI18nService,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.i18n.setLocale(vi_VN);
    this.auth.getInfor().subscribe((data:any)=>{
      this._store.dispatch(
        authAction.getAuthInfoDone({data:data}))
    })
  }
  public url: any[] = [];
   getUrl() {
    this.url = this.router.url.split('/');
    console.log(this.url);

  }
}
