import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { of } from 'rxjs';
import { Router } from '@angular/router';
import { MapType } from '@angular/compiler';
import { ProductService } from '../../core/api/product.service';
import { authAction } from './shared.action';
import { AuthService } from '../../core/api/auth.service';

@Injectable()
export class AuthenEffects {
  _actions = inject(Actions);
  _store = inject(Store);
  _router = inject(Router);
  authService = inject(AuthService);

  // getEvenIdete$ = createEffect(() => {
  //   return this._actions.pipe(
  //     ofType(authAction.getAuthInfoDone),
  //     map((action) => action.data),
  //     exhaustMap((data:any) => {
  //       return this.authService.getwarehouse({ saleId:  data.id}).pipe(
  //         map((rpon) => authAction.getWarehouseIdInfoDone({ data: rpon.data.warehouseId })),
  //         catchError((error) => of(authAction.getWarehouseIdInfoFali())),
  //       );
  //     }),
  //   );
  // });
}
