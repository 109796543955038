import { createReducer, on } from "@ngrx/store";
import { changeCollapsed } from "./main.action";
export interface colapsedStateInterface {
  collapsed: boolean;
}
const colapsedState : colapsedStateInterface = {
  collapsed: false
}
export const ChangeCollapsedReducer = createReducer(
  colapsedState,
  on(changeCollapsed, (state, { data }) => {
    return {
      ...state,
      collapsed:data.collapsed
    };
  }),
)
